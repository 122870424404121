.rectangular-image{
    width: 100%;
    height: 500px;
    object-fit: cover;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    border-radius: 50px 10px;
}
.basic-desc{
    display: flex;
    /* align-items: center; */
}

.basic-desc ul li {
    font-weight: 600;
    margin: 10px 0;
    color: var(--mainColor);
}
.basic-desc ul li span {
    color: #929292;
    margin-left: 8px;
    font-weight: normal !important;
}
.knowlwge-box div{
    width: 50%;
}

.knowlwge-box ul li {
    /* font-weight: 600; */
    margin: 10px 0;
    color:  #929292;
}


/* timeline  */
.vertical-timeline-element-date {
    color: var(--mainColor) !important; 
}

.display-card {
        background: var(--secondColor);
        border-radius: 15px;
        box-shadow: 0 5px 5px rgb(82 63 105 / 5%);
        padding: 1.5rem;
        position: relative;
        cursor: pointer;
    }

.vertical-timeline-element-title
{
    font-size: 24px;
    font-weight: 600;
    font-family: 'Spectral', serif;
}
.vertical-timeline-element-subtitle{
    font-size: 18px;
    font-family: 'Spectral', serif;
}
.read-more{
  display: none;
}	
  /* .business-block:hover  .read-more {
    display: block;

  } */

  /* .business-block:hover {
    background-color: blue;
  } */
  /* .business-block {
    display: flex;
    align-items: center;
    justify-content: space-around;
  } */
.business-block img{
width: 50px;
height: 50px;
margin-bottom: 15px;
}
/* .business-block-text{
  text-align: center;
} */
  .slider-iamge{
    width: auto;
    height: 150px;
    object-fit: cover;
    /* border-radius: 50%; */
  }
.awssld__content{
background-color: var(--secondColor) !important;
height: 200px !important;
}
.awssld__container{
  height: 250px !important;
  padding-bottom: 0 !important;
}
.awssld__box{
  height: 250px !important;
}
.service-count-card{
  background: transparent;
  border: 0.5px solid var(--mainColor);
  box-shadow: 0 5px 5px rgb(82 63 105 / 5%);
  padding: 1.5rem;
  text-align: center;
  position: relative;
}

 .awssld__timer {
  opacity: 0;
  pointer-events: none; /* prevent events (mouse clicking etc.) */
}
/* sidebar */
:root {
    --border: 3px;
    --color1: #6be89b;
    --color2: #0076a5;
    --from: 30deg;
    --distance: 46%;
    --mainColor: black;
    --bodymainColor: #f8f8f8;
    --secondColor: white;
    --btnMain: #dfdfdf;
    --spanColor: #d7dce670;
  }
body {
    font-family: 'Karla', sans-serif !important;
    margin: 0;
    width:100vw; 
overflow-x:hidden;
}

h1, h2, h3, h4, h5 {
    font-family: 'Spectral', serif !important;
    font-weight: 700 !important;
    color: var(--mainColor);
}
a{
    text-decoration: none !important;
}
p {
    color: #929292 !important;
    font-family: 'Karla', sans-serif !important;
    line-height: 32px;
    letter-spacing: 0.5px;
}
.vertical-flex{
    display: flex;
    flex-direction: column;
}
.sidebar{
    height: 100vh;
    width: 20vw;
    padding: 50px 30px 50px 30px;
    text-align: left;
    position: sticky;
    top: 0;
    left: 0;
    background-color: var(--secondColor);
    transition: all 0.4s ease-out;
    /* font-family: 'Karla', sans-serif; */
}
.sidebar h2 {
    font-weight: 600;
    font-family: 'Spectral', serif;
}
.sidebar ul li, .navbar-links ul li{
    padding: 10px 5px;
}
.sidebar ul li a, .navbar-links ul li a{
    text-decoration: none;
    color:#646464;
    font-size: 16px;
    transition: 0.3s;
}
.sidebar ul li a.active, .navbar-links ul li a.active {
    font-weight: 600;
    color: var(--mainColor);
}
.icon{
    margin-right: 10px;
}
.sidebar ul li a:hover{
    color: #717171;
    letter-spacing: 1px;
}
.hero-container{
    padding-top: 100px;  
     
}
.main-container{
    background-color: var(--bodymainColor);
    width: 80% !important;
    padding: 0 70px;
    height: 100%;
    min-height: 100vh;
}

ul {
    list-style: none;
    padding: 0 !important;
    margin: 0 !important;
}

.page-box{
    border: none;
    padding: 5px 10px;
    margin-bottom: 16px;
    text-transform: uppercase;
    background-color: var(--btnMain);
    color: var(--mainColor);
}

hr {
 clear: both; /* takes care of you floated elements */
  padding-top: 20px;
  margin-bottom: 30px;
  border: none;
}

button{
    padding: 10px 15px;
    background-color: var(--mainColor);
    color: var(--secondColor);
    border: none;
    transition: 0.5s ease-in ;
}
button:hover{
    border-radius: 8px;
}

.popClose-btn {
    position: absolute;
    top: -15px;
    right: -15px;
    background: #fff;
    color: black;
    border: 0;
    box-shadow: -2px 2px 13px -4px rgb(0 0 0 / 46%);
    border-radius: 60px;
    font-size: 18px;
    width: 45px;
    height: 45px;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
}

.botIcon{
    position: fixed;
    right: 0;
    top: 50%;
    background: var(--btnMain);
    color: var(--mainColor);
    font-size: 30px;
    width: 55px;
    height: 45px;
    border-radius: 20px 0 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 6px 12px rgb(0 0 0 / 47%);
    cursor: pointer;
}
.nav-bar{
    display: none;
    height: 10vh;
    padding: 0 70px;
    background-color: var(--secondColor);
    transition: all 0.4s ease-in;
}
.hamburger{
    transition: 0.5s ease ;
    font-size: 24px;
    cursor: pointer;
    color: var(--mainColor);
}
.navbar-links
{
    width: 300px;
    height: 90vh;
    background-color: var(--secondColor);
    transition: 0.5s ease ;
}

.activetoggle{
    z-index: 1;
    position: absolute;
    top: 10vh;
    left: 0;
    transition: 0.5s ease-out ;
}
.hidetoggle{
    position: absolute;
    top: 10vh;
    left: -300px;
    transition: 0.5s ease-in ;
}
.breadcrumb{
    position: relative;
    
}
.breadcrumb span{
    color: var(--spanColor);
font-size: 42px;
     display: inline-block;
   font-weight: 600;
    left: 0;
     line-height: 1;
    position: absolute;
   /* text-transform: uppercase; */
    top: 100%;
    transform: translateY(-40%);
     user-select: none;
   white-space: nowrap;
   z-index: -0.5; 
}


/* --------- responsive ----- */
/* small labtop */
@media (max-width: 1000px) {
    .body-container{
        display: flex;
        flex-direction: column;
    }
    .sidebar{
    position: absolute;
    top: 0;
    left: -200px;
    }
   .main-container{
       width: 100vw !important;
   }
.nav-bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;
}

 }
/* tablet */
@media (max-width: 720px) {
    .d-flex{
        flex-direction: column !important;
    }
    .hero-container{
        padding-top: 30px;  
   }
   .main-container{
       background-color: var(--bodymainColor);
       width: 100vw !important;
       padding: 0 10px;
   }
   .basic-desc{
    flex-direction: column;
    align-items: left;
}
.social-media{
   
   
    left: 30% !important;
    
  }
}
/*  mobile */
@media (max-width: 500px) 
{
   
}

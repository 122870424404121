.contact-img{
    width: 100%;
    height: 450px;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    transition: 0.5s ;
    cursor: pointer;
    opacity: 0.5; 
    object-fit: cover;
}
.contact-img:hover{
    transform: scale(1.02);
}
.univ-input {
    width: 100%;
    padding:  10px;
    font-style: italic;
    border: 0.5px solid var(--btnMain);
    background-color: transparent;
}
.send-btn{
    width: 200px !important;
}
.contact-container{
    position: relative;
}
.contact-form{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--mainColor);
    opacity: 0.5; 
}
.contact-form-text{
    text-align: center;
    position: absolute;
    top: 40%;
    left: 10%;
   
}
.contact-form-font  {
    color: var(--bodymainColor) !important;
    margin: 0 !important;
    text-decoration: none !important
}
.contact-form-text-link{
    color: var(--bodymainColor) !important;
    padding: 20px !important;
    text-decoration: none !important;
}
.contact-form-text-link:hover {
    transform: scale(1.1);
    cursor: pointer;
    
    /* color: rgb(107,232,155);
    color: radial-gradient(circle, rgba(107,232,155,1) 18%, rgba(0,118,165,1) 31%); */
  }

  input, textarea {
    width: 100%;
    height: 44px;
    padding: 0 15px;
    border: 0.5px solid var(--mainColor);
    background: transparent;
    color: var(--mainColor);
  }
  .form-field{
    margin-top: 30px;
    position: relative;
  }
  .form-field label {
    background: var(--bodymainColor);
    color: var(--mainColor);
    padding: 0 10px;
    position: absolute;
    top: -13px;
    left: 15px;
    transition: all 0.4s ease-out;
    display: inline-block
  }

 
    textarea {
        height: 150px;
       padding-top: 15px;
       color: var(--mainColor);
    }
  
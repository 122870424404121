.dash-container{
     position: absolute;
     top: 30%;
     left: 30%;
     text-align: center;
     overflow: hidden;
}

.circular-image{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}
.user-img {
    position: relative;
    /* padding: 30px; */
  }
  /* .user-img::after {
    content: '';
    border-radius: 999px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: conic-gradient(
      from var(--from),
      var(--color1),
      var(--color2) var(--distance),
      transparent var(--distance)
    );
    -webkit-mask: radial-gradient(
      farthest-side,
      transparent calc(100% - var(--border)),
      #fff calc(100% - var(--border) + 1px)
    );
    mask: radial-gradient(
      farthest-side,
      transparent calc(100% - var(--border)),
      #fff calc(100% - var(--border) + 1px)
    );
  } */
  .social-media{
    display: flex;
    position: relative;
    /* text-align: center; */
    left: 40%;
    /* align-items: center; */
  }
.social-media li a{
    font-size: 24px;
    margin-right: 14px;
    color: var(--mainColor) !important;
    transition: 0.3s;
    cursor: pointer;
}
.social-media li a:hover {
  transform: scale(1.5) !important;
  color: #6be89b !important;
  color: radial-gradient(circle, rgba(107,232,155,1) 18%, rgba(0,118,165,1) 31%) !important;
}
.typing-text{
  color: var(--mainColor);
}

/* --------- responsive ----- */
/* small labtop */
@media (max-width: 1000px) {
  .dash-container{
    left: 0; 
}
}
.portfolio-img{
    height: 200px;
    width: 100%;
    object-fit: contain;
    transition: transform .2s;
   
}
.portfolio-img:hover
{
    transform: scale(1.1);
    z-index: 1;
}
.portfolio-card{
    background: transparent;
    box-shadow: 0 5px 5px rgb(82 63 105 / 5%);
    padding: 1.5rem;
    position: relative;
    cursor: pointer;
}
.modal-img{
    width: 100%;
    height: 400px;
    object-fit: contain;
}